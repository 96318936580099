// extracted by mini-css-extract-plugin
export var centerOfPage = "index-module--centerOfPage--27020";
export var colRight = "index-module--colRight--207dd";
export var flexColumnContainer = "index-module--flexColumnContainer--2c483";
export var flexGeneralContainer = "index-module--flexGeneralContainer--9d329";
export var imgDescription = "index-module--imgDescription--99904";
export var imgWrapper = "index-module--imgWrapper--c5473";
export var intro = "index-module--intro--0b876";
export var list = "index-module--list--fb407";
export var listItem = "index-module--listItem--06e6d";
export var listItemDescription = "index-module--listItemDescription--f3875";
export var listItemLink = "index-module--listItemLink--02c9e";
export var roundedCorners = "index-module--roundedCorners--8852b";
export var textCenter = "index-module--textCenter--4ece3";