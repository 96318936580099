import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const IndexPage = () => (
    <Layout>
        <Seo title="Home" />
        {/* Projects. */}
        <div className={styles.flexGeneralContainer}>
            <h1>Projects.</h1>
            <div>
                <div class={styles.imgWrapper}>
                    <img src="https://i.postimg.cc/MTtRkycd/grandmagreen.jpg" />
                </div>
            </div>
            <div>
                <div class={styles.imgWrapper}>
                    <img src="https://i.postimg.cc/jSrjXD25/figspromise.png" />
                </div>
            </div>
        </div>

        <div className={styles.flexColumnContainer}>
            {/* Picture of me. */}
            <StaticImage
                src = "../images/websitepic.png"
                loading = "eager"
                width = {250}
                quality={100}
                layout="constrained"
                className={styles.roundedCorners}
            />
            {/* My description goes here. */}
            <div className={styles.colRight}>
                <h1>
                    Hi! Thanks for stopping by my corner of the internet. I'm Jamie.
                </h1>
                <p>
                    I'm a senior at the <i>University of Southern California</i>. I'll be graduating from USC's Games program with a <b>B.S. in Computer Science</b>.
                </p>
                <p>
                    Although my studies are primarily focused on how to make video games, I find pretty much every area of computer science interesting! In particular, I like system design and web development.
                </p>
                <p>
                    Thanks for dropping by!
                </p>
            </div>
        </div>
    </Layout>
)

export const Head = () => <Seo title="Home"/>

export default IndexPage